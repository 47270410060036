// react
import React, { useEffect } from 'react';

// recoil
import { ocrState } from 'src/recoil/recoil';
import { useSetRecoilState } from 'recoil';

// libraries
import { useNavigate } from 'react-router';

// constants
import { paths } from 'src/routes/path';
import { RivConstant } from 'src/constants/rivConstant';

// components
import Header from 'src/components/Header';
import Copyright from 'src/components/Copyright';

// style
import styled from '@emotion/styled';
import styles from 'src/assets/app.module.css';
import Complete_img from 'src/assets/images/ico_complete.svg';

const MsgBox = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  font-size: var(--body2-size);
  line-height: 150%;
  font-family: var(--body2);
  color: var(--text-subdued);
  padding: 0 24px;

  & p {
    font-size: var(--subtitle2-size);
    line-height: 150%;
    font-family: var(--subtitle2-family);
    color: var(--text-secondary);
    text-align: center;
    display: inline-block;
    margin-bottom: 8px;
  }

  & span {
    font-size: var(--body2-size);
    line-height: 150%;
    font-family: var(--body2-family);
    color: var(--text-body2);
    text-align: center;
    display: inline-block;
  }
`;

const MsgButton = styled.button`
  position: relative;
  border-radius: var(--radius-m);
  background-color: var(--control-primary);
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 16px;
  box-sizing: border-box;
  text-align: center;
  font-size: var(--body2-size);
  color: var(--text-inverse);
  font-family: var(--body2-family);
  margin: 32px auto 0;
  border: 0;
`;

export default function Step6() {
  // navigate
  const navigate = useNavigate();
  // recoil
  const setResultData = useSetRecoilState(ocrState);

  /**-------------------------------- useEffect --------------------------------------*/
  /*recoil 최종 초기화*/
  useEffect(() => {
    setResultData({});
    sessionStorage.removeItem(RivConstant.ACCESS_TOKEN_KEY);
  }, [setResultData]);

  /**-------------------------------- 이벤트헨들러 --------------------------------------*/
  // 서비스 홈 이동 버튼 클릭이벤트
  const handleGoBackClick = () => {
    window.close();
    navigate(paths.home);
  };

  return (
    <>
      <div className={styles.cont_box}>
        <Header step="100%" />

        <MsgBox>
          <img src={Complete_img} className={styles.info_img} alt="React" />
          <p>인증이 완료되었습니다.</p>

          <span>사용중인 서비스로 돌아가 계속 진행해 주세요</span>

          <MsgButton onClick={handleGoBackClick}>서비스 홈 이동</MsgButton>
        </MsgBox>
      </div>

      <div className={styles.btm_box}>
        <Copyright />
      </div>
    </>
  );
}
