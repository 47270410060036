import { doRmtIdVer } from 'src/apis/apis';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const getPath = (path) => {
  return `${API_BASE_URL}${path}`;
};

const endpoint = {
  tokenGet: getPath('/rmtIdVer/getToken'), // 토큰 발급
  token: getPath('/rmtIdVer/verifyRivToken'), // 토큰 검증
  doRmtIdVer: getPath('/wv/rmtIdVer/doRmtIdVer') // 신분증 진위확인 API
};

export default endpoint;
