import styled from '@emotion/styled';

const StyledTextTitleForm = styled.div``;

const StyledTitle = styled.h1`
  margin: 0;
  font-size: var(--title1-size);
  line-height: 150%;
  font-family: var(--title1-family);
  color: var(--text-primary);
  text-align: left;
  display: inline-block;
  white-space: pre-line;
  font-weight: 900;
`;

const StyledDesc = styled.div`
  margin-top: 8px;
  font-size: var(--body1-size);
  line-height: 150%;
  font-family: var(--body1-famyly);
  color: var(--text-body);
  text-align: left;
  white-space: pre-line;
`;

function TextTitle({ size, type, title, description }) {
  return (
    <StyledTextTitleForm type={type}>
      <StyledTitle size={size}>{title}</StyledTitle>
      <StyledDesc>{description}</StyledDesc>
    </StyledTextTitleForm>
  );
}

export default TextTitle;
