export const paths = {
  home: '/',
  step2: '/step2',
  step3: '/step3',
  step4: '/step4',
  step5: '/step5',
  step6: '/step6',
  liveInfo: '/live/info',
  live: '/live',
  liveResult: '/live/result'
};
