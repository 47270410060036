import { useRoutes } from 'react-router';
import { paths } from 'src/routes/path';
import MainLayout from 'src/layouts/main-layout';

// 서비스메인
import Step1 from 'src/pages/step1';
import Step2 from 'src/pages/step2';
import Step3 from 'src/pages/step3';
import Step4 from 'src/pages/step4';
import Step5 from 'src/pages/step5';
import Step6 from 'src/pages/step6';
import Page404 from 'src/pages/page404';
import LivenessInfo from 'src/pages/liveness-info';
import Liveness from 'src/pages/liveness';
import LivenessResult from 'src/pages/liveness-result';

export default function Router() {
  return useRoutes([
    // ----------------------------------------------------------------------
    // 서비스메인
    {
      path: paths.home,
      element: (
        <MainLayout>
          <Step1 />
        </MainLayout>
      )
    },
    // step2
    {
      path: paths.step2,
      element: (
        <MainLayout>
          <Step2 />
        </MainLayout>
      )
    },
    // step3
    {
      path: paths.step3,
      element: (
        <MainLayout>
          <Step3 />
        </MainLayout>
      )
    },
    // step4
    {
      path: paths.step4,
      element: (
        <MainLayout>
          <Step4 />
        </MainLayout>
      )
    },
    // step5
    {
      path: paths.step5,
      element: (
        <MainLayout>
          <Step5 />
        </MainLayout>
      )
    },
    // step6
    {
      path: paths.step6,
      element: (
        <MainLayout>
          <Step6 />
        </MainLayout>
      )
    },
    // liveness 안내 페이지
    {
      path: paths.liveInfo,
      element: (
        <MainLayout>
          <LivenessInfo />
        </MainLayout>
      )
    },
    // liveness 촬영
    {
      path: paths.live,
      element: (
        <MainLayout>
          <Liveness />
        </MainLayout>
      )
    },
    // liveness 촬영 결과 페이지
    {
      path: paths.liveResult,
      element: (
        <MainLayout>
          <LivenessResult />
        </MainLayout>
      )
    },
    // ----------------------------------------------------------------------
    // 404
    {
      path: '*',
      element: (
        <MainLayout>
          <Page404 />
        </MainLayout>
      )
    }
  ]);
}
