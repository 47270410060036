// libraries
import { useNavigate } from 'react-router';

// constants
import { paths } from 'src/routes/path';

// components
import Header from 'src/components/Header';
import Button from 'src/components/Button';
import TextTitle from 'src/components/TextTitle';
import Copyright from 'src/components/Copyright';
import ButtonGroup from 'src/components/ButtonGroup';

// style
import styled from '@emotion/styled';
import styles from 'src/assets/app.module.css';
import Step6_img from 'src/assets/images/img-step6.svg';

const ImgBox = styled.div`
  text-align: center;
  margin-top: 32px;
`;

const TextList = styled.ul`
  text-align: left;
  white-space: pre-line;
  list-style: none;
  margin-top: 32px;

  li {
    position: relative;
    padding-left: 10px;
    font-size: var(--body2-size);
    font-family: var(--body2);
    line-height: 150%;
    color: #8c959d;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 7px;
      display: inline-block;
      width: 4px;
      height: 4px;
      border-radius: 20px;
      background-color: #8c959d;
    }

    &:not(:first-of-type) {
      margin-top: 8px;
    }
  }
`;

export default function LivenessInfo() {
  // navigate
  const navigate = useNavigate();

  /*촬영 버튼 클릭이벤트*/
  const handleNextClick = () => {
    navigate(paths.live);
  };

  /*취소 버튼 클릭이벤트*/
  const handleCancelClick = () => {
    navigate(paths.home);
  };

  return (
    <>
      <div className={styles.cont_box}>
        <Header step="75%" />

        <div className={styles.ContWrap}>
          <TextTitle
            size="xlarge"
            type="title"
            title="얼굴인증"
            description={`얼굴 인증을 통한 본인 확인 절차를 진행합니다.`}
          />

          <ImgBox>
            <img src={Step6_img} alt="얼굴인증 샘플 이미지" />

            <TextList>
              <li>영역 안에 얼굴을 맞추고 정면을 바라봐주세요</li>
              <li>얼굴이 정상 인식되면 자동으로 촬영합니다</li>
              <li>마스크, 선글라스와 같이 얼굴을 가리는 방해물은 제거 후 촬영해주세요</li>
            </TextList>
          </ImgBox>
        </div>
      </div>

      <div className={styles.btm_box}>
        <ButtonGroup gap="true" main>
          <Button text="취소" fill="neutral1" size="large" status="active" onClick={handleCancelClick} />
          <Button text="얼굴 촬영" fill="primary" size="large" status="active" onClick={handleNextClick} />
        </ButtonGroup>

        <Copyright />
      </div>
    </>
  );
}
