// react
import { React, useEffect, useState } from 'react';

// apis
import { getTokenApi, validateTokenApi } from 'src/apis/apis';

// libraries
import styled from '@emotion/styled';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

// constants
import { paths } from 'src/routes/path';
import { RivConstant } from 'src/constants/rivConstant';

// components
import Header from 'src/components/Header';
import Button from 'src/components/Button';
import Dialog from 'src/components/Dialog';
import Copyright from 'src/components/Copyright';
import TextTitle from 'src/components/TextTitle';
import ButtonGroup from 'src/components/ButtonGroup';

// style
import styles from 'src/assets/app.module.css';
import Step1a_img from 'src/assets/images/img-step1a.svg';
import Step1b_img from 'src/assets/images/img-step1b.svg';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { deviceInfoState } from 'src/recoil/recoil';
import { detectDevice, detectOs } from 'src/utils/commonUtils';

const Textinfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;

  & item {
    display: flex;
    gap: 24px;
    font-size: 16px;
    line-height: 150%;
    font-family: HanwhaGothic;
    color: #485059;
    text-align: left;
    padding: 18px 24px;

    & span {
      display: block;
      font-size: 14px;
      line-height: 150%;
      font-family: HanwhaGothic;
      color: var(--text-body2);
      text-align: center;
    }
  }
`;

export default function Step1() {
  const navigate = useNavigate();
  // recoil
  const setDeviceInfo = useSetRecoilState(deviceInfoState);
  // state
  const [searchParams] = useSearchParams();
  const [openModal, setOpenModal] = useState(false);

  /**-------------------------------- useEffect --------------------------------------*/
  /*토큰 검사*/
  useEffect(() => {
    const initializeToken = async () => {
      // Query String 토큰
      const queryToken = searchParams.get('authorization');
      // 다른페이지에서 홈으로 오는 경우 session token 사용
      const sessionToken = sessionStorage.getItem(RivConstant.ACCESS_TOKEN_KEY);

      if (queryToken === 'test') {
        const token = await getToken();
        await validateToken(token);
      } else if (queryToken) {
        await validateToken(queryToken);
      } else if (!queryToken && sessionToken) {
        await validateToken(sessionToken);
      } else {
        // TODO: 토큰검사 활성화
        // setOpenModal(true);
        //console.error('===== [useEffect []] ===== : QueryString 토큰 추출 Fail.');
      }
    };
    initializeToken();
    // 접속 기기 환경 감지
    const userAgent = navigator.userAgent.toLowerCase();
    const os = detectOs(userAgent);
    const device = detectDevice(userAgent);
    console.log(`===== [useEffect []] ===== OS: ${os}, Device: ${device}`);
    setDeviceInfo({
      os,
      device
    });
  }, []);

  /**-------------------------------- apis --------------------------------------*/
  /*토큰 발급 API 호출 : 임시*/
  const getToken = async () => {
    const data = {
      header: {},
      payload: {
        custId: '4063705844'
      }
    };
    const response = await getTokenApi(data);

    if (response.header.rsltCode === '0000') {
      return response.payload.accessToken;
    } else {
      console.error('===== [Token Create API Fail] ===== rsltMsgeCntn: ', data.header.rsltMsgeCntn);

      return '';
    }
  };

  /*토큰 검증 API 호출*/
  const validateToken = async (token) => {
    const reqData = {
      token
    };
    try {
      const response = await validateTokenApi(reqData);

      if (response.header.rsltCode === '0000') {
        sessionStorage.setItem(RivConstant.ACCESS_TOKEN_KEY, token);
      } else {
        console.error('===== [Token Validation API rsltCode] ===== rsltMsgeCntn: ', data.header.rsltMsgeCntn);
        // setOpenModal(true);
      }
    } catch (e) {
      console.error('===== [validateToken 통신에러] ===== error: ', e);
      // setOpenModal(true);
    }
  };

  /**-------------------------------- 이벤트 헨들러 --------------------------------------*/
  /*시작하기 클릭이벤트*/
  const handleClickStart = () => {
    navigate(paths.step2);
  };

  /**
   * 토큰에러모달 - 확인 클릭이벤트
   * 토큰 에러시 더이상 진행 불가기에 exit 처리
   * */
  const handleModalClose = () => {
    // clear data
    sessionStorage.removeItem(RivConstant.ACCESS_TOKEN_KEY);

    setOpenModal(false);
    window.close();
  };

  return (
    <>
      <div className={styles.cont_box}>
        <Header />
        <div className={styles.ContWrap}>
          <TextTitle
            size="xlarge"
            type="title"
            title={`비대면 인증을 위해 \n준비해 주세요`}
            description={`고객님의 실명 확인을 확인할 수 있는 \n신분증 및 얼굴 촬영이 필요합니다.`}
          />

          <Textinfo>
            <item>
              <img src={Step1a_img} alt="info img" />
              <p>
                신분증
                <span>주민등록증 또는 운전면허증</span>
              </p>
            </item>
            <item>
              <img src={Step1b_img} alt="info img" />
              <p>
                휴대폰
                <span>본인 명의 기기</span>
              </p>
            </item>
          </Textinfo>
        </div>
      </div>

      <div className={styles.btm_box}>
        <ButtonGroup gap="true" main>
          <Button text="시작하기" fill="primary" size="large" status="active" onClick={handleClickStart} />
        </ButtonGroup>
        <Copyright />
        {openModal && (
          <Dialog
            title="토큰 에러 발생"
            description="에러가 발생했습니다. 다시 시도해주세요."
            buttonContents={[
              {
                text: '확인',
                fill: 'primary',
                onClick: handleModalClose
              }
            ]}
          />
        )}
      </div>
    </>
  );
}
