import styled from '@emotion/styled';

const BtnStyle = styled.button`
  width: 100%;
  background: ${({ fill }) => (fill === 'primary' ? 'var(--control-primary)' : 'var(--control-neutral-03)')};
  color: ${({ fill }) => (fill === 'primary' ? 'var(--text-inverse)' : 'var(--text-secondary)')};
  font-size: var(--subtitle2-size);
  font-family: var(--subtitle2-family);
  border: none;
  border-radius: ${({ size }) => (size === 'xsmall' ? '4px' : '8px')};
  padding: ${({ size }) => {
    switch (size) {
      case 'xsmall':
        return '5.5px 12px';
      case 'small':
        return '9.5px 16px';
      case 'medium':
        return '13.5px 16px';
      case 'large':
        return '16px';
      default:
        return '16px';
    }
  }};
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  cursor: pointer;
  font-weight: 700;
`;

function Button({ type, text, fill, size, status, onClick }) {
  return (
    <BtnStyle type={type} fill={fill} size={size} status={status} onClick={onClick}>
      {text}
    </BtnStyle>
  );
}

export default Button;
