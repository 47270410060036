import styled from '@emotion/styled';
import styles from 'src/assets/app.module.css';
import Info_img from 'src/assets/images/ico_info.svg';
import { useNavigate } from 'react-router';
import { paths } from 'src/routes/path';

const MsgBox = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  font-size: var(--body2-size);
  line-height: 150%;
  font-family: var(--body2);
  color: var(--text-subdued);
  padding: 0 24px;

  & p {
    font-size: var(--subtitle2-size);
    line-height: 150%;
    font-family: var(--subtitle2-family);
    color: var(--text-secondary);
    text-align: center;
    display: block;
    margin-bottom: 8px;
  }

  & span {
    font-size: var(--body2-size);
    line-height: 150%;
    font-family: var(--body2-family);
    color: var(--text-body2);
    text-align: center;
    display: block;
  }
`;

const MsgButton = styled.button`
  position: relative;
  border-radius: var(--radius-m);
  background-color: var(--control-primary);
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 16px;
  box-sizing: border-box;
  text-align: center;
  font-size: var(--body2-size);
  color: var(--text-inverse);
  font-family: var(--body2-family);
  margin: 32px auto 0;
  border: 0;
`;

export default function Page404() {
  const navigate = useNavigate();

  const handleHomeClick = () => {
    navigate(paths.home, { replace: true });
  };

  return (
    <MsgBox>
      <img src={Info_img} className={styles.info_img} alt="React" />
      <p>
        죄송합니다.
        <br />
        요청하신 페이지를 찾을 수 없습니다.
      </p>

      <span>
        찾으시는 주소가 잘못 입력되었거나
        <br />
        변경 혹은 삭제되어 주소를 사용할 수 없어요.
        <br />
        입력하신 페이지의 주소를 다시 한번
        <br />
        확인해주세요
      </span>

      <MsgButton onClick={handleHomeClick}>서비스 홈 이동</MsgButton>
    </MsgBox>
  );
}
