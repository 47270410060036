import { selector } from 'recoil';
import { deviceInfoState, livenessState } from 'src/recoil/recoil';

export const livenessSelector = selector({
  key: 'livenessSelector',
  get: ({ get }) => {
    return get(livenessState);
  },
  set: ({ set }, newValue) => {
    set(livenessState, (prevValue) => ({
      ...prevValue,
      ...newValue
    }));
  }
});

export const isMobileSelector = selector({
  key: 'isMobileSelector',
  get: ({ get }) => {
    const deviceInfo = get(deviceInfoState);
    if (deviceInfo.device === 'MOBILE') {
      return true;
    }

    return false;
  }
});
