import { useRecoilValue } from 'recoil';
import { livenessState } from 'src/recoil/recoil';

export default function LivenessResult() {
  const result = useRecoilValue(livenessState);

  const transformToUrl = () => {
    return URL.createObjectURL(result.blob);
  };

  return (
    <>
      <p>정면</p>
      <img src={result.images[0]} alt="정면" />
      <p>우측</p>
      <img src={result.images[1]} alt="우측" />
      <p>좌측</p>
      <img src={result.images[2]} alt="좌측" />
      <video controls muted width="600" style={{ marginTop: 2 }}>
        <source src={transformToUrl()} type="video/mp4" />
      </video>
    </>
  );
}
