import styled from '@emotion/styled';

const BackBar = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  left: 0%;
  background-color: #f1f2f5;
`;

const Progress = styled.div`
  position: absolute;
  height: 100%;
  top: 0%;
  right: 100%;
  bottom: 0%;
  left: 0%;
  background-color: var(--brand-deepyellow-200);
  width: ${(props) => props.step};
`;

const ProgressbarRoot = styled.div`
  width: 100%;
  position: relative;
  height: 2px;
`;

function ProgressBar({ step }) {
  return (
    <ProgressbarRoot>
      <BackBar />
      <Progress step={step} />
    </ProgressbarRoot>
  );
}

export default ProgressBar;
