import styled from '@emotion/styled';

const CopyrightBox = styled.div`
  position: relative;
  font-size: 12px;
  line-height: 140%;
  color: var(--text-neutral-01);
  text-align: center;
  margin-bottom: 20px;
`;

function Copyright() {
  return <CopyrightBox>(C) Hanwha Life Corp. All rights reserved.</CopyrightBox>;
}

export default Copyright;
