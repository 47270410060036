// react
import React, { useEffect, useRef, useState } from 'react';

// recoil
import { useSetRecoilState } from 'recoil';
import { livenessState } from 'src/recoil/recoil';

// libraries
import { useNavigate } from 'react-router';

// constants
import { paths } from 'src/routes/path';

// component
import Loading from 'src/components/Loading';
import Dialog from 'src/components/Dialog';

// style
import styled from '@emotion/styled';
import Face_bg from 'src/assets/images/face_bg.svg';
import Face_img from 'src/assets/images/face_canvas.svg';

const BgRoot = styled.div`
  position: relative;
  height: 100%;
  background-color: rgba(0 0 0 / 0.9);
`;

const TextTitle = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  font-size: var(--title1-size);
  color: var(--text-inverse);
  font-family: var(--title1- family);
  padding: 32px 20px 0;
`;

const Maskimg = styled.div`
  position: relative;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    display: inline-block;
    width: 100%;
    height: 100%;
    background-color: #fff;
    mask-image: url(${Face_img});
    mask-repeat: no-repeat;
    mask-position: center center;
    mask-size: 60%;
    aspect-ratio: 1;
    object-fit: cover;
  }
`;

const CameraVideo = styled.video`
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
  mask-image: url(${Face_bg});
  mask-repeat: no-repeat;
  mask-position: center center;
  mask-size: 100%;
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
`;

const Textinfo = styled.div`
  position: absolute;
  left: 50%;
  bottom: 80px;
  transform: translateX(-50%);
  width: 100%;
  font-size: var(--body1-size);
  line-height: 150%;
  font-family: var(--body1-family);
  color: var(--system-white);
  text-align: center;
  display: inline-block;
`;

export default function Liveness() {
  // navigate
  const navigate = useNavigate();
  // recoil
  const setResult = useSetRecoilState(livenessState);
  // state
  const [isLoading, setIsLoading] = useState(true);
  const [faceRecModal, setFaceRecModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [faceDirection, setFaceDirection] = useState(null);
  // ref
  const initialValues = useRef({});
  const faceChecker = useRef(null);

  /**-------------------------------- useEffect --------------------------------------*/
  useEffect(() => {
    /*liveness 라이브러리 설정 값*/
    initialValues.current = {
      video: document.querySelector('video#face_verify'), // video 태크
      modelUrl: '/js/blazeface/model.json', // model.json
      directionChangeInterval: 300, // 얼굴 방향 전환시 딜레이 ms
      faceCheckCount: 3, // 얼굴 체크 검증 횟수 (default: 3)
      resolution: { width: 640, height: 480 }, // 해상도
      onVideoReady,
      onPermissionDenied,
      onDetectionFailed,
      onDetectionComplete,
      setGuideLine,
      setDirection,
      onFaceCheckSuccess
    };

    // liveness 가동
    faceChecker.current = new FaceChecker();
    faceChecker.current.getFaceDirection(initialValues.current);
  }, []);

  /**-------------------------------- liveness --------------------------------------*/
  /*현재 진행중인 얼굴 기울기 체크 진행 방향을 매개변수로 전달*/
  const setDirection = (direction) => {
    /**
     * 0. front
     * 1. left
     * 2. right
     */
    console.log('[setDirection] direction: ', direction);
    setFaceDirection(direction);
  };

  /*비디오가 준비되어 얼굴 탐지를 시작할 때 콜백함수*/
  const onVideoReady = () => {
    setIsLoading(false);
  };

  /*비디오 권한을 획득하지 못했을 때 발생하는 콜백함수*/
  const onPermissionDenied = () => {
    setModalMessage('카메라 권한을 확인해주세요');
    setOpenModal(true);
  };

  /*일정 시간동안 얼굴이 감지되지 않아 실패일 때 콜백함수*/
  const onDetectionFailed = () => {
    setFaceRecModal(true);
  };

  /*체크 완료 후 콜백함수*/
  const onDetectionComplete = async (result) => {
    console.log('result: ', result);
    setResult(result);
    navigate(paths.liveResult);
  };

  /*얼굴이 인식 영역에 들어왔을 때 발생하는 콜백함수*/
  const setGuideLine = (status) => {
    // status: boolean / true: 인색, false: 비인식
    console.log('[setGuideLine] status: ', status);
  };

  /*얼굴 체크 성공 회수를 전달하는 콜백 함수*/
  const onFaceCheckSuccess = (count) => {
    console.log(`${count}회 성공`);
  };
  /**-------------------------------- 이벤트 헨들러 --------------------------------------*/
  /*에러모달 - 확인버튼 클릭이벤트*/
  const handleModalClose = () => {
    setOpenModal(false);
  };

  /*얼굴인식실패 모달 - 홈으로 클릭이벤트*/
  const handleGoHomeClick = () => {
    navigate(paths.home);
  };

  /*얼굴인식실패 모달 - 재촬영 클릭이벤트*/
  const handleRetryClick = () => {
    setFaceRecModal(false);
    faceChecker.current.getFaceDirection(initialValues.current);
  };

  const getFaceDirection = () => {
    switch (faceDirection) {
      case 0:
        return '정면';
      case 2:
        return '우측';
      default:
        return '좌측';
    }
  };

  return (
    <>
      <BgRoot>
        <Maskimg>
          <CameraVideo id="face_verify" playsInline muted />

          {!faceDirection && <TextTitle>얼굴인증</TextTitle>}
          <Textinfo>
            얼굴인증을 위해
            <br />
            얼굴을 {getFaceDirection()}으로 천천히 움직여 주세요
          </Textinfo>
        </Maskimg>
      </BgRoot>
      {isLoading && <Loading description={'잠시만 기다려 주세요\n카메라를 여는 중 입니다.'} />}
      {faceRecModal && (
        <Dialog
          title="얼굴 인식 실패"
          description={'얼굴 인식의 상태를 확인해 주세요'}
          buttonContents={[
            {
              text: '홈으로',
              fill: 'neutral1',
              onClick: handleGoHomeClick
            },
            {
              text: '재촬영',
              fill: 'primary',
              onClick: handleRetryClick
            }
          ]}
        />
      )}
      {openModal && (
        <Dialog
          title="에러 발생"
          description={modalMessage}
          buttonContents={[
            {
              text: '확인',
              fill: 'primary',
              onClick: handleModalClose
            }
          ]}
        />
      )}
    </>
  );
}
