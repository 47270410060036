// react
import React, { useEffect, useRef, useState } from 'react';

// recoil
import { ocrState } from 'src/recoil/recoil';
import { useRecoilState, useRecoilValue } from 'recoil';
import { isMobileSelector } from 'src/recoil/selector';

// libraries
import { useNavigate } from 'react-router';

// apis
import { doRmtIdVer } from 'src/apis/apis';

// js
import { formatDate } from 'src/utils/commonUtils';

// constants
import { paths } from 'src/routes/path';
import { RivConstant } from 'src/constants/rivConstant';

// components
import Dialog from 'src/components/Dialog';
import Button from 'src/components/Button';
import Header from 'src/components/Header';
import Loading from 'src/components/Loading';
import TextTitle from 'src/components/TextTitle';
import FieldSet from 'src/components/FieldSet';
import ButtonGroup from 'src/components/ButtonGroup';

// style
import styled from '@emotion/styled';
import styles from 'src/assets/app.module.css';
import Copyright from 'src/components/Copyright';

const ImgBox = styled.div`
  text-align: center;
  margin-top: 30px;
  canvas {
    max-width: 100%;
  }
`;

const FieldSetBox = styled.div``;

export default function Step4() {
  // navigate
  const navigate = useNavigate();
  // recoil
  const ocrValue = useRecoilValue(ocrState);
  const [resultData, setResultData] = useRecoilState(ocrState);
  const isMobile = useRecoilValue(isMobileSelector);
  // state
  const [isLoading, setIsLoading] = useState(false);
  const [ocrFailModal, setOcrFailModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  // ref
  const isDisableAPI = useRef(false);

  /**-------------------------------- useEffect --------------------------------------*/
  useEffect(() => {
    /*토큰 검사*/
    const getToken = sessionStorage.getItem(RivConstant.ACCESS_TOKEN_KEY);

    if (!getToken || getToken.trim().length < 1) {
      // TODO: 토큰검사 활성화
      // setOpenModal(true);
      return;
    }

    /*OCR 결과 데이터 검사*/
    if (Object.keys(ocrValue).length < 1) {
      navigate(paths.step3);
    }
  }, []);

  /**-------------------------------- apis --------------------------------------*/
  const handleComplete = async () => {
    if (isDisableAPI.current) {
      return false;
    }
    setIsLoading(true);
    isDisableAPI.current = true;
    const data = {
      name: resultData.name, // 신분증성명
      cardType: resultData.cardType, // 신분증 구분
      faceExResult: resultData.faceExResult, // 사진특징점평가점수
      faceExFeature: resultData.faceExFeature, // 얼굴 특징점
      idNumberEnc: resultData.idNumberEnc, // 주민번호 암호화
      issueDateEnc: resultData.issueDateEnc, // 발급일자 암호화
      driverLicenseNumberEnc: resultData.driverLicenseNumberEnc, // 면허증번호 암호화
      faceDvsnCode: isMobile ? '10' : '11', // 대면 비대면 구분코드(모바일: 10, 그 외: 11)
      originIdImageFileNm: resultData.originIdImageFileNm, // 신분증 원본 이미지 파일명
      photoIdImageFileNm: resultData.photoIdImageFileNm // 얼굴 크롭 이미지 파일명
    };

    try {
      // 신분증 진위확인 API 호출
      let response = await doRmtIdVer(data);

      if (response.payload.rspnCode === '000') {
        // TODO: liveness 분기 처리
        setResultData({});
        navigate(paths.step6);
      } else {
        setOcrFailModal(true);
      }
      response = null;
    } catch (error) {
      console.error('===== [doRmtIdVer 통신에러] ===== error: ', error);
      setOcrFailModal(true);
    } finally {
      setIsLoading(false);
      isDisableAPI.current = false;
    }
  };

  /**-------------------------------- 이벤트헨들러 --------------------------------------*/
  /*다시촬영 클릭이벤트*/
  const goBack = () => {
    setResultData({});
    navigate(paths.step3);
  };

  /*인식실패 모달 - 홈으로 클릭이벤트*/
  const goHome = () => {
    setResultData({});
    setOcrFailModal(false);
    navigate(paths.home);
  };

  /*토큰에러모달 - 확인 클릭이벤트*/
  const handleModalClose = () => {
    // clear data
    setResultData({});
    sessionStorage.removeItem(RivConstant.ACCESS_TOKEN_KEY);

    setOpenModal(false);
    window.close();
    navigate(paths.home);
  };

  return (
    <>
      <div className={styles.cont_box}>
        <Header step="75%" />

        <div className={styles.ContWrap}>
          <TextTitle
            size="xlarge"
            type="title"
            title={`신분증이 정상적으로 \n촬영되었나요?`}
            description={`신분증 사진 및 인식된 정보가 틀리면 \n이용이 제한되니 반드시 정보를 확인해주세요`}
          />

          <ImgBox id="resultCanvasWrapper">
            <canvas id="resultCanvas" style={{ height: 0 }}></canvas>
          </ImgBox>

          <FieldSetBox>
            <FieldSet label="이름" value={ocrValue?.name} type="text" placeholder="" />

            {ocrValue?.maskedIdNumber && (
              <FieldSet
                label="주민등록 번호"
                value={ocrValue?.maskedIdNumber.substring(0, 6)}
                value2={ocrValue?.maskedIdNumber.substring(6, ocrValue?.maskedIdNumber.length)}
                type="text"
                multi="true"
                placeholder=""
              />
            )}

            <FieldSet label="발급일자" value={formatDate(ocrValue?.maskedIssueDate)} type="text" placeholder="" />
          </FieldSetBox>
        </div>
      </div>
      <div className={styles.btm_box}>
        <ButtonGroup gap="true" main>
          <Button text="다시 촬영" fill="neutral1" size="large" status="active" onClick={goBack} />
          <Button text="완료" fill="primary" size="large" status="active" onClick={handleComplete} />
        </ButtonGroup>
        <Copyright />
      </div>
      {isLoading && <Loading description={'잠시만 기다려 주세요\n진위여부 판별 중 입니다.'} />}
      {ocrFailModal && (
        <Dialog
          title="신분증 인식 실패"
          description="원활한 촬영을 위헤 네모 영역 안으로 신분증을 맞춰주세요."
          buttonContents={[
            {
              text: '홈으로',
              fill: 'neutral1',
              onClick: goHome
            },
            {
              text: '재촬영',
              fill: 'primary',
              onClick: goBack
            }
          ]}
        />
      )}

      {openModal && (
        <Dialog
          title="에러 발생"
          description="에러가 발생했습니다. 다시 시도해주세요."
          buttonContents={[
            {
              text: '확인',
              fill: 'primary',
              onClick: handleModalClose
            }
          ]}
        />
      )}
    </>
  );
}
