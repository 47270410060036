import { atom } from 'recoil';

export const deviceInfoState = atom({
  key: 'deviceInfoState',
  default: {
    os: '',
    device: ''
  }
});

export const ocrState = atom({
  key: 'ocrState',
  default: {}
});

export const livenessState = atom({
  key: 'livenessState',
  default: {
    images: [],
    blob: null
  }
});
