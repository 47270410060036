// react
import React, { useEffect, useRef, useState } from 'react';

// recoil
import { useRecoilState } from 'recoil';
import { ocrState } from 'src/recoil/recoil';

// libraries
import { useNavigate } from 'react-router';

// scripts
import { contentsLoaded, scanner } from 'src/scripts/robiscan';

// constants
import { paths } from 'src/routes/path';
import { RivConstant } from 'src/constants/rivConstant';

// components
import Dialog from 'src/components/Dialog';
import Loading from 'src/components/Loading';

// style
import styled from '@emotion/styled';
import Mask_bg from 'src/assets/images/mask_bg.svg';
import Mask_img from 'src/assets/images/mask_canvas.svg';
import cameraBtn from 'src/assets/icon/btn-camera.svg';
import Step3a_img from 'src/assets/images/img-step3a.svg';
import Step3b_img from 'src/assets/images/img-step3b.svg';

const BgRoot = styled.div`
  position: relative;
  height: 100%;
  background-color: rgba(0 0 0 / 0.9);
`;

const P = styled.p`
  margin: 0;
`;

const TextTitle = styled.div`
  position: relative;
  z-index: 100;
  font-size: var(--subtitle2-size);
  line-height: 150%;
  font-family: var(--subtitle2-family);
  color: #fff;
  text-align: left;
  display: inline-block;
  max-width: 300px;
  margin: 84px 20px 0;
`;

const CameraVideo = styled.video`
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
  mask-image: url(${Mask_bg});
  mask-repeat: no-repeat;
  mask-position: center center;
  mask-size: 100%;
  visibility: hidden;
`;

const CameraCanvas = styled.canvas`
  width: 100%;
  height: 100%;
  padding: 10px;
  object-fit: cover;
`;

const ButtonCameraIcon = styled.img`
  width: 100%;
  position: absolute;
  left: 50%;
  bottom: 96px;
  transform: translateX(-50%);
  max-width: 100%;
  overflow: hidden;
  width: 64px;
  height: 64px;
  cursor: pointer;
  z-index: 3;
`;

const OcrResult = styled.section`
  display: none;
`;

const OcrReadFile = styled.input`
  display: none;
`;

const OcrReadReault = styled.div`
  display: none;
`;

const Maskimg = styled.div`
  position: relative;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    display: inline-block;
    width: 100%;
    height: 100%;
    background-color: #fff;
    mask-image: url(${Mask_img});
    mask-repeat: no-repeat;
    mask-position: center center;
    mask-size: 89.8%;
    aspect-ratio: 1;
    object-fit: cover;
  }
`;

const CameraInfo = styled.div`
  position: absolute;
  bottom: 102px;
  display: flex;
  padding: 0 20px;
  gap: 16px;

  & item {
    position: relative;
    font-size: var(--caption-size);
    line-height: 140%;
    font-family: var(--caption-family);
    color: var(--text-neutral-02);
    text-align: center;
    display: inline-block;
    padding: 0 20px;
    word-break: keep-all;

    & img {
      display: block;
      margin: 0 auto 8px;
      width: 44px;
    }
  }
`;

export default function Step3() {
  // navigate
  const navigate = useNavigate();
  // recoil
  const [resultData, setResultData] = useRecoilState(ocrState);
  // states
  const [openModal, setOpenModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingMessage, setLoadingMessage] = useState('잠시만 기다려 주세요\n카메라를 여는 중 입니다.');
  // ref
  const childrenRef = useRef(null);

  /**-------------------------------- useEffect --------------------------------------*/
  useEffect(() => {
    /*토큰 검사*/
    const getToken = sessionStorage.getItem(RivConstant.ACCESS_TOKEN_KEY);

    if (!getToken || getToken.trim().length < 1) {
      // TODO: 토큰검사 활성화
      //setOpenModal(true);
      //return;
    }

    /*OCR 통신 contentsLoaded*/
    /*카메라 모듈 초기화 성공 핸들러*/
    const handleSuccess = () => {
      setIsLoading(false);
      document.getElementById('scanView').style.visibility = 'visible';
    };

    /*카메라 모듈 초기화 실패 핸들러*/
    const handleFailure = () => {
      setIsLoading(false);
      setModalMessage('카메라 모듈 초기화 실패. 카메라가 준비되었는지 확인해 주세요.');
      setOpenModal(true);
    };

    /*OCR 통신 중 Loading 스피너 open*/
    const handleOnLoading = () => {
      setLoadingMessage('잠시만 기다려 주세요\n신분증 정보를 확인 중 입니다.');
      setIsLoading(true);
    };

    /*OCR 통신 중 Loading 스피너 close*/
    const handleFinishLoading = () => {
      setIsLoading(false);
    };

    // OCR 통신 CALL
    contentsLoaded(handleSuccess, handleFailure, handleOnLoading, handleFinishLoading);

    return () => {
      scanner?.callbacks.pageClose();
    };
  }, []);

  /*OCR 결과 처리*/
  useEffect(() => {
    let current;

    const observer = new MutationObserver(() => {
      const getOcrData = document.querySelector('#ocrResultData').dataset.result || 'init';
      const getOcrResultData =
        document.querySelector('#ocrResultData').dataset.resultHeader !== ''
          ? JSON.parse(document.querySelector('#ocrResultData').dataset.resultHeader)
          : 'init';

      if (
        getOcrData &&
        getOcrData.trim() !== '' &&
        getOcrData.trim() !== 'null' &&
        getOcrData.trim() !== 'init' &&
        getOcrData.trim() !== 'reset'
      ) {
        setResultData(JSON.parse(getOcrData));
        document.querySelector('#ocrResultData').dataset.result = 'reset';
      }

      if (
        getOcrData.trim() === '' ||
        !getOcrData ||
        (getOcrData.trim() !== 'init' && getOcrResultData.rsltCode !== '0000' && getOcrData.trim() !== 'reset')
      ) {
        if (getOcrResultData.rsltCode === '0011' || getOcrResultData.rsltCode === '0056') {
          setModalMessage(getOcrResultData.rsltMsgeCntn);
          setOpenModal(true);
        } else {
          setModalMessage('원활한 촬영을 위헤 네모 영역 안으로 신분증을 맞춰주세요.');
          setOpenModal(true);
        }
      }
    });

    if (childrenRef.current) {
      observer.observe(childrenRef.current, {
        childList: true,
        attributes: true,
        subtree: true
      });
      current = childrenRef.current;
    }

    return () => {
      if (current) {
        observer.disconnect(current);
      }
    };
  }, [setOpenModal, setResultData, modalMessage]);

  /*OCR 결과 민증 / 운전면허증에 따라 step4 또는 step5로 navigate 처리*/
  useEffect(() => {
    const getOcrResultData =
      document.querySelector('#ocrResultData').dataset.resultHeader &&
      document.querySelector('#ocrResultData').dataset.resultHeader !== ''
        ? JSON.parse(document.querySelector('#ocrResultData').dataset.resultHeader)
        : 'init';

    const isAbleNext =
      getOcrResultData.rsltCode !== '0010' &&
      getOcrResultData.rsltCode !== '0011' &&
      getOcrResultData.rsltCode !== '0056';

    if (resultData && Object.keys(resultData).length > 0) {
      if (resultData.cardType === '1' && !openModal && isAbleNext) {
        navigate(paths.step4);
      } else if (resultData.cardType === '2' && !openModal && isAbleNext) {
        navigate(paths.step5);
      } else {
        setOpenModal(true);
      }
    }
  }, [resultData, navigate, openModal, setOpenModal]);

  /**-------------------------------- 이벤트 핸들러 --------------------------------------*/
  /*모달 - 재촬영 클릭이벤트*/
  const handleRestartClick = () => {
    if (openModal) {
      setOpenModal(false);
    }
    scanner?.callbacks.reShoot();
  };

  /*모달 - 홈으로 클릭이벤트*/
  const handleGoHomeClick = () => {
    // clear data
    setResultData({});

    setOpenModal(false);
    navigate(paths.home);
  };

  /*토큰에러모달 - 확인 클릭이벤트*/
  const handleErrorModalClose = () => {
    // clear data
    setResultData({});
    sessionStorage.removeItem(RivConstant.ACCESS_TOKEN_KEY);

    setOpenErrorModal(false);
    window.close();
    navigate(paths.home);
  };

  return (
    <>
      <BgRoot id="scanContainer" className="scan-container">
        <Maskimg>
          <CameraVideo id="scanView" playsInline web muted webkitPlaysInline />
          <div id="scanBoxMask" className="" />
          <div id="scanBoxGuide" className="">
            <div id="scanBoxLoading" className="" />
            <div id="scanBoxPassportPhoto" className="" />
            <div id="scanBoxPassportMrz" className="" />
            <TextTitle id="scanBoxDesc">
              <P>표시된 영역에 신분증을 맞춰 배치 후</P>
              <P>하단 버튼을 누르면 촬영됩니다.</P>
            </TextTitle>
          </div>
          <CameraInfo>
            <item>
              <img src={Step3a_img} alt="주민등록증 샘플 이미지" />
              어두운 바닥에서 촬영해 주세요
            </item>
            <item>
              <img src={Step3b_img} alt="주민등록증 샘플 이미지" />
              빛이 반사되지 않도록 주의해주세요
            </item>
          </CameraInfo>
          <button id="autoCameraToggle" className="" style={{ display: 'none' }}></button>

          {/* <CameraBox className="scan-box-wrap"> */}
          <CameraCanvas id="scanBoxMask" style={{ display: 'none' }} />
          {/* </CameraBox> */}

          <div id="camera-btn_layout">
            <ButtonCameraIcon id="takeCameraBtn" className="camera-btn__take" src={cameraBtn} alt="Camera Action" />
          </div>
          <OcrReadFile id="file-capture-manual" type="file" name="files" capture="camera" accept="image/jpeg" />
        </Maskimg>
      </BgRoot>
      <OcrResult id="resultContainer" className="">
        <p id="resultBoxDesc" className=""></p>
        <canvas id="resultCanvas" className=""></canvas>
        <button id="resetBtn" className=""></button>
        <button id="redetectBtn" className=""></button>
        <button id="sendServerBtn" className=""></button>
      </OcrResult>
      <OcrReadReault id="ocrResultData" ref={childrenRef}></OcrReadReault>
      {isLoading && <Loading description={loadingMessage} />}
      {openModal && (
        <Dialog
          title="신분증 인식 실패"
          description={modalMessage}
          buttonContents={[
            {
              text: '홈으로',
              fill: 'neutral1',
              onClick: handleGoHomeClick
            },
            {
              text: '재촬영',
              fill: 'primary',
              onClick: handleRestartClick
            }
          ]}
        />
      )}

      {openErrorModal && (
        <Dialog
          title="토큰 에러 발생"
          description={'에러가 발생했습니다.\\n다시 시도해주세요.'}
          buttonContents={[
            {
              text: '확인',
              fill: 'primary',
              onClick: handleErrorModalClose
            }
          ]}
        />
      )}
    </>
  );
}
