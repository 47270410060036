// react
import React, { useEffect, useState } from 'react';

// libraries
import { useNavigate } from 'react-router';

// constants
import { paths } from 'src/routes/path';
import { RivConstant } from 'src/constants/rivConstant';

// components
import Dialog from 'src/components/Dialog';
import Button from 'src/components/Button';
import Header from 'src/components/Header';
import Copyright from 'src/components/Copyright';
import TextTitle from 'src/components/TextTitle';
import ButtonGroup from 'src/components/ButtonGroup';

// style
import styled from '@emotion/styled';
import styles from 'src/assets/app.module.css';
import idCard from 'src/assets/illustrator/id-card.svg';

const ImgBox = styled.div`
  text-align: center;
  margin-top: 32px;
`;

const TextList = styled.ul`
  text-align: left;
  white-space: pre-line;
  list-style: none;
  margin-top: 32px;

  li {
    position: relative;
    padding-left: 10px;
    font-size: var(--body2-size);
    font-family: var(--body2);
    line-height: 150%;
    color: #8c959d;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 7px;
      display: inline-block;
      width: 4px;
      height: 4px;
      border-radius: 20px;
      background-color: #8c959d;
    }

    &:not(:first-of-type) {
      margin-top: 8px;
    }
  }
`;

export default function Step2() {
  // navigate
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);

  /**-------------------------------- useEffect --------------------------------------*/
  /*토큰 검사*/
  useEffect(() => {
    const getToken = sessionStorage.getItem(RivConstant.ACCESS_TOKEN_KEY);

    if (!getToken || getToken.trim().length < 1) {
      // TODO: 토큰검사 활성화
      // setOpenModal(true);
    }
  }, []);

  /**-------------------------------- 이벤트 헨들러 --------------------------------------*/
  // 촬영 버튼 클릭이벤트
  const handleNextClick = () => {
    navigate(paths.step3);
  };

  // 취소 버튼 클릭이벤트
  const handleCancelClick = () => {
    navigate(paths.home);
    // TODO: native 적용 시 결과 값 return 으로 변경
    // 취소 결과 값 전송
    //window.ReactNativeWebView.postMessage('9999');
  };

  /*에러모달 - 확인 버튼 클릭이벤트*/
  const handleModalClose = () => {
    // clear data
    sessionStorage.removeItem(RivConstant.ACCESS_TOKEN_KEY);

    setOpenModal(false);
    window.close();
    navigate(paths.home);
  };

  return (
    <>
      <div className={styles.cont_box}>
        <Header step="25%" />

        <div className={styles.ContWrap}>
          <TextTitle
            size="xlarge"
            type="title"
            title="신분증 촬영"
            description={`주민등록증 또는 운전면허증을 준비해 주세요.`}
          />

          <ImgBox>
            <img src={idCard} className={styles.step2_img} alt="주민등록증 샘플 이미지" />

            <TextList>
              <li>복사본이나 사진은 사용할 수 없습니다.</li>
              <li>오염이나 훼손된 신분증으로는 확인 어렵습니다.</li>
            </TextList>
          </ImgBox>
        </div>
      </div>
      <div className={styles.btm_box}>
        <ButtonGroup gap="true" main>
          <Button text="취소" fill="neutral1" size="large" status="active" onClick={handleCancelClick} />
          <Button text="신분증 촬영" fill="primary" size="large" status="active" onClick={handleNextClick} />
        </ButtonGroup>
        <Copyright />

        {openModal && (
          <Dialog
            title="에러 발생"
            description="에러가 발생했습니다. 다시 시도해주세요."
            buttonContents={[
              {
                text: '확인',
                fill: 'primary',
                onClick: handleModalClose
              }
            ]}
          />
        )}
      </div>
    </>
  );
}
