import React from 'react';
import styled from '@emotion/styled';
import ProgressBar from './ProgressBar';
import closeIcon from 'src/assets/icon/close.svg';
import { useNavigate } from 'react-router';
import { useRecoilState } from 'recoil';
import { ocrState } from '../recoil/recoil';
import { RivConstant } from 'src/constants/rivConstant';

const ActionIcon = styled.div`
  width: 28px;
  height: 28px;
`;
const Titlearea = styled.div`
  flex: 1;
  height: 27px;
`;

const ActionIconArea1 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
const Container1 = styled.div`
  align-self: stretch;
  flex: 1;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--spacing-150) var(--spacing-250);
  gap: var(--spacing-150);
`;

const HeaderRoot = styled.div`
  width: 100%;
  position: relative;
  height: 52px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

function Header({ step }) {
  const navigate = useNavigate();
  const [resultData, setResultData] = useRecoilState(ocrState);
  /**-------------------------------- 이벤트 헨들러 --------------------------------------*/
  /*취소 버튼 클릭이벤트*/
  const handleCancelClick = () => {
    sessionStorage.removeItem(RivConstant.ACCESS_TOKEN_KEY);
    setResultData({});

    window.close();
    navigate('/');
  };

  return (
    <HeaderRoot>
      <Container1>
        <ActionIcon />
        <Titlearea />
        <ActionIcon />
        <ActionIcon onClick={handleCancelClick}>
          <img src={closeIcon} alt="Close" />
        </ActionIcon>
      </Container1>

      <ProgressBar step={step} />
    </HeaderRoot>
  );
}

export default Header;
