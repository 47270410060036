import axios from 'axios';
import { RivConstant } from 'src/constants/rivConstant';

/*Axios 인스턴스 생성*/
const axiosInstance = axios.create({
  withCredentials: true,
  timeout: 8000,
  headers: {
    'Content-Type': 'application/json'
  }
});

/**-------------------------------- 요청 인터셉터 --------------------------------------*/
axiosInstance.interceptors.request.use((config) => {
  const accessToken = sessionStorage.getItem(RivConstant.ACCESS_TOKEN_KEY);
  if (accessToken) {
    config.headers = {
      'Riv-Access-Token': accessToken
    };
  }

  return config;
});

/**-------------------------------- 응답 인터셉터 --------------------------------------*/
axiosInstance.interceptors.response.use((response) => {
  return response;
});

export default axiosInstance;
